




























































import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract/ui'

import { WhereToBuyModule } from '../WhereToBuy.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'WhereToBuyUi' })
export class WhereToBuyUi extends AbstractModuleUi<WhereToBuyModule> {
  public foundShops: Array<AnyObject> | null = []
  public searchQuery: string = ''

  public isOpen: boolean = false

  public readonly toImageProps = toImageProps

  /**
   * Determines whether shops data is present in payload.
   */
  public get hasShopsData (): boolean {
    return typeof this.content.shopsData !== 'undefined' &&
        Array.isArray(this.content.shopsData) &&
        this.content.shopsData.length > 0
  }

  /**
   * Determines whether brands are present in payload.
   */
  public get hasBrands (): boolean {
    return typeof this.content.brands !== 'undefined' &&
        Array.isArray(this.content.brands) &&
        this.content.brands.length > 0
  }

  /**
   * Determines the array of string to search for.
   */
  public get cities (): Array<string> {
    if (!this.content.shopsData) {
      return []
    }

    return this.content.shopsData.map<string>((shop) => shop.city)
  }

  /**
   * Determines the filtered list of cities.
   */
  public get filteredCities (): Array<string> {
    return [
      ...new Set(this.cities.filter(city => city.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1))
    ]
  }

  /**
   * Searches the list of shops by the keyword.
   *
   * @param query - search query.
   * @param keyword - keyword to search by.
   */
  public searchByKeyword (query: string, keyword: string = 'city'): AnyObject[] | null {
    if (!this.content.shopsData) {
      return null
    }

    if (!query.length) {
      return []
    }

    const shops = [...this.content.shopsData]

    return shops.filter((shop) => {
      return shop[keyword].toLowerCase().indexOf(query.toLowerCase()) > -1
    })
  }

  /**
   * Determines the action for search on selected item in popover.
   */
  public onSearch (query?: string): void {
    if (query) {
      this.searchQuery = query
    }

    this.foundShops = this.searchByKeyword(this.searchQuery)

    this.isOpen = false
  }

  /**
   * Handles the model change of the searchQuery property.
   */
  public onSearchQueryUpdate (query: string): void {
    this.searchQuery = query
    this.isOpen = query.length > 0 && this.filteredCities.length > 0
  }
}

export default WhereToBuyUi
